<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="450px"
  >
    <el-form :model="ruleForm" :rules="rules" :show-message="false" ref="bindErp" size="small" label-width="90px">
      <el-form-item label="思锐账号" prop="account">
        <el-input
          v-model.trim="ruleForm.account"
          type="text"
          maxlength="11"
          clearable
          placeholder="请输入用户名/手机号"
        />
      </el-form-item>
      <el-form-item label="选择用户名" prop="userId">
        <el-select v-model="ruleForm.userId" placeholder="请选择">
          <el-option v-for="item in userOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="验证码" prop="smsCaptcha">
        <SMSCode v-model="ruleForm.smsCaptcha" :smsCaptcha="ruleForm.smsCaptcha" :phone="ruleForm.phone" />
      </el-form-item>
      <div class="flex font-size-12 text-666">
        <div class="w-90 text-right">绑定的优势：</div>
        <div class="flex1">
          <div>1、绑定思锐ERP账号后，订货系统的采购订单将自动同步到该ERP的客户订单</div>
          <div>2、订货系统的采购订单与该ERP的客户订单状态将同步更新</div>
          <div>3、节省在ERP手动创建采购单的时间</div>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="text-right">
      <el-button plain size="small" @click="closedFn">取消</el-button>
      <el-button type="primary" size="small" @click="onSure">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import SMSCode from "@/components/SMSCode";

const defaultForm = {
  account: "", //思锐账号
  phone: "13412341234",
  userId: null //选择用户名
};
export default {
  name: "BindErpBox",
  components: {
    SMSCode
  },
  data() {
    return {
      title: "思锐账号绑定",
      ruleForm: {
        ...defaultForm
      },
      rules: {},
      userOptions: [
        { id: 1, name: "001@001C" },
        { id: 2, name: "002@001C" },
        { id: 3, name: "003@001C" },
        { id: 4, name: "001@001N" }
      ]
    };
  },
  watch: {
    "$attrs.visible": {
      handler(val) {
        if (val) {
          if (this.$refs.bindErp) {
            this.$refs.bindErp.resetFields();
          }
          this.ruleForm = { ...defaultForm };
        }
      }
    }
  },
  methods: {
    closedFn() {
      this.$emit("update:visible", false);
    },
    onSure() {
      this.$refs.bindErp.validate((valid, err) => {
        if (valid) {
          //调用接口
          const params = {
            ...this.ruleForm
          };

          console.log("params", params);
          this.closedFn();
        } else {
          const errTips = err[Object.keys(err)[0]][0].message;
          this.$message.warning({ message: errTips, showClose: true });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
